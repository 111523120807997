<!--
 * @Description:财务管理系统 财务管理 停车缴费记录 parkChargeStatistics
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 14:25:46
 * @LastEditors: xiaosheng 11511201+fxiaosheng@user.noreply.gitee.com
-->

<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true" :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px" label="车牌号">
              <el-input
                v-model="searchForm.plateNumber"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px" label="停车场">
              <el-select
                class="search-input"
                clearable
                v-model="searchForm.parkId"
                placeholder="全部"
              >
                <el-option
                  v-for="(item, index) in parkNameList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px" label="车位号">
              <el-input
                v-model="searchForm.parkSpaceNumber"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px" label="进场时间">
              <el-date-picker
                v-model="entranceTimes"
                type="datetimerange"
                align="right"
                unlink-panels
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px" label="离场时间">
              <el-date-picker
                v-model="leaveTimes"
                type="datetimerange"
                align="right"
                unlink-panels
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="topButton">
            <el-button type="primary" @click="queryButton">查 询</el-button>
            <el-button type="info" style="margin-left: 17px" @click="resetForm"
              >重 置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
      <!-- <el-row>
        <el-col :span="6" class="topButton">
          <el-button type="primary" @click="queryButton">查 询</el-button>
          <el-button type="info" style="margin-left: 17px" @click="resetForm"
            >重 置</el-button
          >
        </el-col>
      </el-row> -->
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <!-- <span>停车收费统计</span> -->
        <el-button type="info" class="tableTitleButton" @click="exportFile"
          >导出</el-button
        >
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table
          :tableColumnList="tableColumnList"
          :data="tableList"
          :map="listMap"
          :paginationConfig="paginationConfig"
          :tableConfig="tableConfig"
          @getList="getList"
        >
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data() {
    //这里存放数据
    return {
      //表格
      paginationConfig: { layout: "prev,sizes,pager,next,jumper", total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 50 },
      entranceTimes: [],
      leaveTimes: [],
      tableColumnList: [
        {
          prop: "plateNumber",
          label: "车牌号",
        },
        {
          prop: "plateColor",
          label: "车牌颜色",
        },
        {
          prop: "entranceTime",
          label: "进场时间",
        },
        {
          prop: "leaveTime",
          label: "离场时间",
        },
        {
          prop: "parkName",
          label: "停车场",
        },
        {
          prop: "parkSpaceNumber",
          label: "车位号",
        },
        {
          prop: "receivableMoneyAmount",
          label: "新能源计费金额",
        },
        {
          prop: "bluePlateNumberMoneyAmount",
          label: "无优惠计费金额",
        },
      ],
      // 表格接收后台数据
      tableList: { list: [] },
      searchForm: {},
      pageNum: 1,
      pageSize: 50,
      //统计时间
      time: [],
      current: [],
      parkNameList: [], //停车场下拉框
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    entranceTimes(val) {
      if (val) {
        this.searchForm.entranceStartTime = val[0];
        this.searchForm.entranceEndTime = val[1];
      } else {
        this.searchForm.entranceStartTime = "";
        this.searchForm.entranceEndTime = "";
      }
    },
    leaveTimes(val) {
      if (val) {
        this.searchForm.leaveStartTime = val[0];
        this.searchForm.leaveEndTime = val[1];
      } else {
        this.searchForm.leaveStartTime = "";
        this.searchForm.leaveEndTime = "";
      }
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.queryCurrentDay();
    this.queryTableList(); //初始化页面
    this.queryParkNameList(); //获取停车场
  },
  //方法集合
  methods: {
    // 导出
    exportFile() {
      this.$axios
        .post(
          this.$downloadBaseUrl + "order/exportNewEnergy",
          this.searchForm,
          {
            responseType: "blob",
            headers: {
              userToken: localStorage.getItem("userToken"),
            },
          }
        )
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "新能源差异.xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
        });
    },
    // 获取当天时间
    queryCurrentDay() {
      let day = new Date();
      let a = day.getFullYear();
      let b = day.getMonth() + 1;
      let c = day.getDate();
      if (JSON.stringify(b).length === 1) {
        b = "0" + b;
      }
      if (JSON.stringify(c).length === 1) {
        c = "0" + c;
      }
      this.current[0] = a + "-" + b + "-" + c + " 00:00:00";
      this.current[1] = a + "-" + b + "-" + c + " 23:59:59";
    },
    //初始化表格数据
    queryTableList() {
      this.searchForm["pageNum"] = this.pageNum;
      this.searchForm["pageSize"] = this.pageSize;
      this.$parkChargeStatistics
        .queryNewEnergyList(this.searchForm)
        .then((res) => {
          console.log(res, "初始化表格");
          this.tableList.list = res.resultEntity.content;
          this.paginationConfig.total = res.resultEntity.totalElements;
        });
    },
    //分页
    getList(change) {
      this.pageSize = change.pageSize;
      this.pageNum = change.pageNum;
      this.queryTableList();
    },
    //顶部查询按钮
    queryButton() {
      this.pageNum = 1;
      this.queryTableList();
    },
    //顶部重置按钮
    resetForm() {
      this.searchForm = {};
      this.entranceTimes = [];
      this.leaveTimes = [];
      this.queryTableList();
    },
    //获取停车场名称
    queryParkNameList() {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
      };
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity;
      });
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
$topSelectHeight: 184px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.mainbody {
  height: 100%;

  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background("blockBg");
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background("blockBg");
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}
.search_column2 {
  margin-top: 20px;
}
</style>
